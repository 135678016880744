import { FC } from "react";
import { CTACard, Notification} from "@in/component-library";
import css from "./intro.module.scss";

interface Props {}

const Intro: FC<Props> = (props) => {
  return (
    <div className={css["intro"]}>
      <div className={css["intro--content"]}>
        <div className={css["intro__text"]}>
          <h1>Vi hjelper norsk næringsliv videre</h1>
          <p>
            Drømmer du om å starte noe eget? Utvikler du ny teknologi? Eller
            kanskje du allerede er klar for å teste løsningen din i nye markeder?
            Uansett hvor langt du har kommet, kan riktig støtte gjøre veien til
            suksess enklere. Vi forteller deg hva det offentlige kan gjøre for
            deg.
          </p>
        </div>

        <div className={css["intro__card"]}>
          <CTACard
            href="https://raskavklaring.innovasjonnorge.no/"
            header="Fyll ut skjema (ca. 15 minutter)"
            text="Logg inn med ID-porten"
          />
        </div>
        <Notification type="generic">
          Denne tjenesten er fortsatt under utvikling. Knappen over sender deg
          derfor videre til Innovasjon Norges nettsider.
        </Notification>
      </div>
    </div>
  );
};

export default Intro;
